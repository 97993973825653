// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchSalesEditPaymentUrl,
  fetchSalesFiltersUrl,
  fetchSalesListUrl,
  fetchSalesInfoUrl,
  fetchSalesSendEmailUrl,
  fetchSalesExportExcelUrl,
  fetchSalesExportCommissionsTxtUrl,
  fetchSalesExportCommissionsHtmlUrl,
  fetchSalesExportSalesTxtUrl,
  fetchSalesExportSalesHtmlUrl,
  fetchSalesExportOperationalHtmlUrl,
} from './constants';
import {
  SalesEditPaymentResponseType,
  SalesFiltersResponseType,
  SalesListResponseType,
  SalesInfoResponseType,
  SalesSendEmailResponseType,
  SalesExportResponseType,
  SalesExportCommissionsTxtResponseType,
  SalesExportCommissionsHtmlResponseType,
  SalesExportSalesTxtResponseType,
  SalesExportSalesHtmlResponseType,
  SalesExportOperationalHtmlResponseType,
} from './types';

export const fetchSalesFiltersService = async () => {
  return await api
    .get<SalesFiltersResponseType>(fetchSalesFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesListService = async (params: {
  // userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
  page: string;
  driverId: string;
  serviceOrderUserId: string;
}) => {
  return await api
    .get<SalesListResponseType>(fetchSalesListUrl, {
      params: params,
      timeout: 100000, // 100 seconds
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesInfoService = async (params: { saleId: string }) => {
  return await api
    .get<SalesInfoResponseType>(`${fetchSalesInfoUrl}/${params.saleId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesEditPaymentService = async (params: {
  saleId: string;
  paymentMethod: string;
  login: string;
  password: string;
}) => {
  const { saleId, paymentMethod, login, password } = params;
  return await api
    .put<SalesEditPaymentResponseType>(
      `${fetchSalesEditPaymentUrl}/${saleId}/paymentmethod`,
      JSON.stringify({
        paymentMethod: paymentMethod,
        login: login,
        password: password,
      }),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesSendEmailService = async (params: {
  saleId: string;
  email: string;
  name: string;
}) => {
  return await api
    .post<SalesSendEmailResponseType>(
      `${fetchSalesSendEmailUrl}/${params.saleId}/sendreceipt`,
      { email: params.email, name: params.name },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportExcelService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportResponseType>(fetchSalesExportExcelUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportCommissionsTxtService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportCommissionsTxtResponseType>(
      fetchSalesExportCommissionsTxtUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportCommissionsHtmlService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportCommissionsHtmlResponseType>(
      fetchSalesExportCommissionsHtmlUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportSalesTxtService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportSalesTxtResponseType>(fetchSalesExportSalesTxtUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportSalesHtmlService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportSalesHtmlResponseType>(fetchSalesExportSalesHtmlUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSalesExportOperationalHtmlService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  paymentMethod: string;
  routeAreaId: string;
  routeId: string;
  reprint: string;
  hasNotPlate: string;
  hasIrregularity: string;
  hasDiscount: string;
  profileId: string;
  posMachine: string;
}) => {
  return await api
    .get<SalesExportOperationalHtmlResponseType>(
      fetchSalesExportOperationalHtmlUrl,
      {
        params: params,
        timeout: 120000,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
