import axios from 'axios';
import { setupInterceptors } from './interceptors';

/**
 * Here you can export functions with corresponding services / endpoints.
 * You can always use the services directly from the specific moduleServices
 * file as well.
 * This is to keep a track of all available services as a list.
 * I think it makes is easy to maintain when you have a long long list as your
 * app gradually scales.
 */

const axiosAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});

export const api = axiosAPI;
setupInterceptors();

export * from './AdministrativeDashboard';
export * from './Areas';
export * from './Auth';
export * from './Configurations';
export * from './Credentials';
export * from './Notifications';
export * from './OperationalDashboard';
export * from './Permissions';
export * from './PrivateSales';
export * from './Routes';
export * from './Sessions';
export * from './Sales';
export * from './Users';
