import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import { map } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportCommissionsHtmlResponseType } from '../../../../services/Sales/types';
import { salesListSlice } from '../../../../stores';
import { logoUrl } from '../../../../theme';

export const exportSalesCommissionsToHTML = async (
  apiData: SalesExportCommissionsHtmlResponseType,
  startDate: string,
  finalDate: string,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  workerOne: (apiData: SalesExportCommissionsHtmlResponseType) => Promise<
    {
      type: string;
      value: number;
      profileId: number;
    }[]
  >,
  workerTwo: (
    apiData: SalesExportCommissionsHtmlResponseType,
    handledCommissions: {
      type: string;
      value: number;
      profileId: number;
    }[],
    startDate: string,
    finalDate: string,
  ) => Promise<
    {
      userId: number;
      name: string;
      login: string;
      startDate: string;
      endDate: string;
      total: number;
      operators: {
        profileId: number;
        registerDate: string;
        commissions: {
          type: string;
          count: number;
          sumPrice: number;
          sumMinutes: string;
          commission: number;
        }[];
      }[];
    }[]
  >,
  workerThree: (
    handledData: {
      userId: number;
      name: string;
      login: string;
      startDate: string;
      endDate: string;
      total: number;
      operators: {
        profileId: number;
        registerDate: string;
        commissions: {
          type: string;
          count: number;
          sumPrice: number;
          sumMinutes: string;
          commission: number;
        }[];
      }[];
    }[],
  ) => Promise<
    {
      fileName: string;
      title: string;
      subtitle: string;
      body: string;
    }[]
  >,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  let handledApiData: {
    fileName: string;
    title: string;
    subtitle: string;
    body: string;
  }[] = [];
  if (apiData.commissions && apiData.sales) {
    handledApiData = await workerOne(apiData).then(async (workerOneReturn) => {
      dispatch(salesListSlice.actions.setSalesExportPercentage(40));
      return workerTwo(apiData, workerOneReturn, startDate, finalDate).then(
        async (workerTwoReturn) => {
          dispatch(salesListSlice.actions.setSalesExportPercentage(70));
          return workerThree(workerTwoReturn);
        },
      );
    });
  }
  dispatch(salesListSlice.actions.setSalesExportPercentage(80));

  // Creating files in .zip
  const zip = new JSZip();
  map(handledApiData, function (item) {
    zip.file(
      `${item.fileName}.html`,
      `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Recibo de comissão</title>
        <style>


          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 10px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 8px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 31px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 13px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 11px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 10px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 26px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 14px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td class="wrapper">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>
                            <h1>${item.title}</h1>
                            <h3>${item.subtitle}</h3>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                                <tr>
                                  <td align="left">
                                    <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                                      <thead>
                                        <tr>
                                          <th align="left">Matrícula</th>
                                          <th align="left">Nome</th>
                                          <th align="left">Data</th>
                                          <th align="left">Categoria</th>
                                          <th align="left">Quantidade</th>
                                          <th align="left">Valor</th>
                                          <th align="left">Comissão</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${item.body}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" style="margin-top:96px">
                              <tbody>
                                <tr>
                                  <td width="50%" style="padding: 0 5px">
                                    <hr></hr>
                                    <p align="center">Local e Data</p>
                                  </td>
                                  <td width="50%" style="padding: 0 5px">
                                    <hr></hr>
                                    <p align="center">Declaro que recebi o valor da comissão do período apurado</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
    );
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(90));

  // Creating all operators file in .zip
  let allOperatorsBody = '';
  map(handledApiData, function (item) {
    allOperatorsBody += `<tr>
    <td>
      <h1>${item.title}</h1>
      <h3>${item.subtitle}</h3>
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td align="left">
              <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                <thead>
                  <tr>
                    <th align="left">Matrícula</th>
                    <th align="left">Nome</th>
                    <th align="left">Data</th>
                    <th align="left">Categoria</th>
                    <th align="left">Quantidade</th>
                    <th align="left">Valor</th>
                    <th align="left">Comissão</th>
                  </tr>
                </thead>
                <tbody>
                  ${item.body}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table border="0" cellpadding="0" cellspacing="0" style="margin-top:96px">
        <tbody>
          <tr>
            <td width="50%" style="padding: 0 5px">
              <hr></hr>
              <p align="center">Local e Data</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </td>
            <td width="50%" style="padding: 0 5px">
              <hr></hr>
              <p align="center">Declaro que recebi o valor da comissão do período apurado</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>`;
  });
  zip.file(
    `TODOS OS FUNCIONARIOS.html`,
    `
    <!doctype html>
    <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Recibo de comissão</title>
      <style>


        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }

        body {
          background-color: white;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 10px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

          table td {
            font-family: sans-serif;
            font-size: 10px;
            vertical-align: top;
          }

        /* -------------------------------------
          BODY & CONTAINER
        ------------------------------------- */

        .body {
          background-color: white;
          width: 100%;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          Margin: 0 auto !important;
          /* makes it centered */
          max-width: 100%;
          padding: 10px;
          /*width: 100%;*/
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          Margin: 0 auto;
          max-width: 100%;
          padding: 10px;
        }

        /* -------------------------------------
          HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #fff;
          border-radius: 3px;
          width: 100%;
        }

        .wrapper {
          box-sizing: border-box;
          padding: 20px;
        }

        .footer {
          clear: both;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 8px;
            text-align: center;
          }

        /* -------------------------------------
          TYPOGRAPHY
        ------------------------------------- */
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: sans-serif;
          font-weight: 400;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 15px;
        }

        h1 {
          font-size: 31px;
          font-weight: 600;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 8px;
        }

        h3 {
          font-size: 13px;
          font-weight: 600;
        }

        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 11px;
          font-weight: normal;
          margin: 0;
          Margin-bottom: 15px;
        }

          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px;
          }

        a {
          color: #3498db;
          text-decoration: underline;
        }

        /* -------------------------------------
          BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }

          .btn > tbody > tr > td {
            padding-bottom: 15px;
          }

          .btn table {
            width: auto;
          }

            .btn table td {
              background-color: #ffffff;
              border-radius: 5px;
              text-align: center;
            }

          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
          }

        .btn-primary table td {
          background-color: #3498db;
        }

        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff;
        }

        /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .last {
          margin-bottom: 0;
        }

        .first {
          margin-top: 0;
        }

        .align-center {
          text-align: center;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .clear {
          clear: both;
        }

        .mt0 {
          margin-top: 0;
        }

        .mb0 {
          margin-bottom: 0;
        }

        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }

        .powered-by a {
          text-decoration: none;
        }

        hr {
          border: 0;
          border-bottom: 1px solid black;
          Margin: 30px 0 10px;
        }

        /* -------------------------------------
            CUSTOM CSS BY ME
          ------------------------------------- */

        .table-adjustment tbody td, .table-adjustment thead th {
          padding: 5px;
        }

        /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 26px !important;
            margin-bottom: 10px !important;
          }

          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 14px !important;
          }

          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important;
          }

          table[class=body] .content {
            padding: 0 !important;
          }

          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important;
          }

          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }

          table[class=body] .btn table {
            width: 100% !important;
          }

          table[class=body] .btn a {
            width: 100% !important;
          }

          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important;
          }
        }

        /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%;
          }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
              line-height: 100%;
            }

          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }

          .btn-primary table td:hover {
            background-color: #34495e !important;
          }

          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important;
          }
        }
      </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                    <table border="0" cellpadding="0" cellspacing="0">
                      ${allOperatorsBody}
                    </table>
                  </td>
                </tr>
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
    `,
  );
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `relatorio-comissoes-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const handleApiDataSalesCommissionsToHTMLPartOne = (
  apiData: SalesExportCommissionsHtmlResponseType,
) => {
  // Create handledCommissions array with all types of commissions with values per 1 min
  const handledCommissions: {
    type: string;
    value: number;
    profileId: number;
  }[] = [];

  // Preparing commissions from apiData first
  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.commissions.map((item) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const timeInMinutes = item.time.split(':');
    if (item.type !== 'Moto') {
      handledCommissions.push({
        type: item.type,
        value:
          item.price /
          (parseInt(timeInMinutes[0]) * 60 +
            parseInt(timeInMinutes[1]) +
            parseInt(timeInMinutes[2]) / 60),
        profileId: item.profileId,
      });
    } else {
      handledCommissions.push({
        type: item.type,
        value: item.price,
        profileId: item.profileId,
      });
    }
  });

  return handledCommissions;
};

export const handleApiDataSalesCommissionsToHTMLPartTwo = (
  apiData: SalesExportCommissionsHtmlResponseType,
  handledCommissions: {
    type: string;
    value: number;
    profileId: number;
  }[],
  startDate: string,
  finalDate: string,
) => {
  // Populating handledData
  const handledData: {
    userId: number;
    name: string;
    login: string;
    startDate: string;
    endDate: string;
    total: number;
    operators: {
      profileId: number;
      registerDate: string;
      commissions: {
        type: string;
        count: number;
        sumPrice: number;
        sumMinutes: string;
        commission: number;
      }[];
    }[];
  }[] = [];

  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.sales.map((item) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const userIdx = handledData.findIndex((auxItem) => {
      return auxItem.userId === item.userId;
    });
    const commissionObj =
      item.profileId !== 8
        ? // eslint-disable-next-line lodash/prefer-lodash-method
          handledCommissions.find((auxItem) => {
            return auxItem.type === item.type && auxItem.profileId === 0;
          })
        : // eslint-disable-next-line lodash/prefer-lodash-method
          handledCommissions.find((auxItem) => {
            return auxItem.type === item.type && auxItem.profileId === 8;
          });

    if (commissionObj) {
      let commission = 0;
      if (item.type === 'Moto') {
        commission = item.count * commissionObj.value;
      } else if (item.type === 'Evento') {
        // eslint-disable-next-line lodash/prefer-lodash-method
        const splitTime = item.registerDate.split('-');
        // eslint-disable-next-line lodash/prefer-lodash-method
        const eventOriginalValue = apiData.commissions.find((item) => {
          return item.type === 'Evento';
        })?.price;

        if (
          parseInt(splitTime[0]) < 2022 ||
          (parseInt(splitTime[0]) === 2022 && parseInt(splitTime[1]) < 12)
        ) {
          commission = eventOriginalValue
            ? (item.sumPrice / 3.5) * eventOriginalValue
            : 0;
        } else if (
          parseInt(splitTime[0]) < 2024 ||
          (parseInt(splitTime[0]) === 2024 && parseInt(splitTime[1]) < 2) ||
          (parseInt(splitTime[0]) === 2024 &&
            parseInt(splitTime[1]) === 2 &&
            parseInt(splitTime[2]) < 10)
        ) {
          commission = eventOriginalValue
            ? (item.sumPrice / 4.0) * eventOriginalValue
            : 0;
        } else {
          commission = eventOriginalValue
            ? (item.sumPrice / 5.0) * eventOriginalValue
            : 0;
        }
      } else {
        commission = parseInt(item.sumMinutes) * commissionObj?.value;
      }

      if (userIdx !== -1) {
        // eslint-disable-next-line lodash/prefer-lodash-method
        const operatorsIdx = handledData[userIdx].operators.findIndex(
          (auxItem) => {
            return auxItem.registerDate === item.registerDate;
          },
        );
        if (operatorsIdx !== -1) {
          handledData[userIdx].operators[operatorsIdx].commissions.push({
            type: item.type,
            count: item.count,
            sumPrice: item.sumPrice,
            sumMinutes: item.sumMinutes,
            commission: commission,
          });
          handledData[userIdx].total += commission;
        } else {
          handledData[userIdx].operators.push({
            profileId: item.profileId,
            registerDate: item.registerDate,
            commissions: [
              {
                type: item.type,
                count: item.count,
                sumPrice: item.sumPrice,
                sumMinutes: item.sumMinutes,
                commission: commission,
              },
            ],
          });
          handledData[userIdx].total += commission;
        }
      } else {
        handledData.push({
          userId: item.userId,
          name: item.name,
          login: item.login,
          startDate: startDate,
          endDate: finalDate,
          total: commission,
          operators: [
            {
              profileId: item.profileId,
              registerDate: item.registerDate,
              commissions: [
                {
                  type: item.type,
                  count: item.count,
                  sumPrice: item.sumPrice,
                  sumMinutes: item.sumMinutes,
                  commission: commission,
                },
              ],
            },
          ],
        });
      }
    }
  });

  return handledData;
};

export const handleApiDataSalesCommissionsToHTMLPartThree = (
  handledData: {
    userId: number;
    name: string;
    login: string;
    startDate: string;
    endDate: string;
    total: number;
    operators: {
      profileId: number;
      registerDate: string;
      commissions: {
        type: string;
        count: number;
        sumPrice: number;
        sumMinutes: string;
        commission: number;
      }[];
    }[];
  }[],
) => {
  // Creating <tr>s
  const htmlStuff: {
    fileName: string;
    title: string;
    subtitle: string;
    body: string;
  }[] = [];

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  // eslint-disable-next-line lodash/prefer-lodash-method
  handledData.map((data) => {
    const fileName = `${data.name} (${data.login})`;
    const title = `Recibo de comissão (${data.name})`;
    const subtitle = `Período apurado: ${formatDateTime(
      data.startDate,
    )} - ${formatDateTime(data.endDate)}`;
    let body = '';

    // eslint-disable-next-line lodash/prefer-lodash-method
    data.operators.map((operator) => {
      // eslint-disable-next-line lodash/prefer-lodash-method
      operator.commissions.map((commission, index) => {
        body += `
        <tr>
          <td>${index === 0 ? data.login : ''}</td>
          <td>${index === 0 ? data.name : ''}</td>
          <td>${index === 0 ? formatDateTime(operator.registerDate) : ''}</td>
          <td>${commission.type}</td>
          <td>${commission.count}</td>
          <td>${
            // eslint-disable-next-line lodash/prefer-lodash-method
            commission.sumPrice.toFixed(2).toString().replace('.', ',')
          }</td>
          <td>${
            // eslint-disable-next-line lodash/prefer-lodash-method
            commission.commission.toFixed(2).toString().replace('.', ',')
          }</td>
        </tr>
        `;
      });
    });

    body += `
    <tr>
      <td align="right" colspan="6">Total:</td>
      <td>${
        // eslint-disable-next-line lodash/prefer-lodash-method
        data.total.toFixed(2).toString().replace('.', ',')
      }</td>
    </tr>
    `;

    htmlStuff.push({
      fileName: fileName,
      title: title,
      subtitle: subtitle,
      body: body,
    });
  });

  return htmlStuff;
};
