import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPrivateSalesInfoService } from '../../services';
import { PrivateSaleStateType } from './types';

const initialState: PrivateSaleStateType = {
  privateSaleInfo: null,
  privateSaleInfoLoading: false,
  privateSaleInfoIsOpen: false,
};

export const fetchPrivateSaleInfo = createAsyncThunk(
  'privateSalesInfo',
  async (params: { privateSaleId: string }) => {
    return await fetchPrivateSalesInfoService(params);
  },
);

export const privateSaleSlice = createSlice({
  name: 'privateSalesInfo',
  initialState,
  reducers: {
    togglePrivateSaleInfoIsOpen(state) {
      state.privateSaleInfoIsOpen
        ? (state.privateSaleInfoIsOpen = false)
        : (state.privateSaleInfoIsOpen = true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrivateSaleInfo.pending, (state) => {
      return {
        ...state,
        privateSaleInfoLoading: true,
      };
    }),
      builder.addCase(fetchPrivateSaleInfo.fulfilled, (state, res) => {
        return {
          ...state,
          privateSaleInfo: {
            id: res.payload.data.id,
            situation: res.payload.data.situation,
            plate: res.payload.data.plate,
            paymentType: res.payload.data.paymentType,
            registerDateTime: res.payload.data.registerDateTime,
            startDateTime: res.payload.data.startDateTime,
            privateParkingId: res.payload.data.privateParkingId,
            privateParkingName: res.payload.data.privateParkingName,
            driverId: res.payload.data.driverId,
            price: res.payload.data.price,
          },
          privateSaleInfoLoading: false,
        };
      }),
      builder.addCase(fetchPrivateSaleInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          privateSaleInfoLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { togglePrivateSaleInfoIsOpen } = privateSaleSlice.actions;
