import { Typography, Skeleton } from '@mui/material';
import { find, times } from 'lodash';
import React from 'react';
import { Modal } from '../../../components';
import { useDispatch, useSelector, privateSaleSlice } from '../../../stores';
import { handleDateTime, handleMoney } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const PrivateSaleInfoModal: React.FC = () => {
  const dispatch = useDispatch();
  const { privateSaleInfo, privateSaleInfoLoading, privateSaleInfoIsOpen } =
    useSelector((state) => state.privateSaleReducer);
  const { filters } = useSelector((state) => state.privateSalesFiltersReducer);

  const handleDriverText = (driverId: number | undefined): string => {
    if (filters && driverId) {
      let info = undefined;
      info = find(filters.drivers, ['id', driverId]);
      if (info !== undefined) {
        return `${info.value}`;
      } else return `Indisponível`;
    }
    return `Indisponível`;
  };

  return (
    <Modal
      open={privateSaleInfoIsOpen}
      onClose={() =>
        dispatch(privateSaleSlice.actions.togglePrivateSaleInfoIsOpen())
      }
      title='Detalhamento'
    >
      <>
        {privateSaleInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(14, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Ticket:
                </Typography>
                <Typography>{privateSaleInfo?.id}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Veículo:
                </Typography>
                <Typography>{privateSaleInfo?.plate}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Data de Registro:
                </Typography>
                <Typography>
                  {handleDateTime(privateSaleInfo?.registerDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Data de Entrada:
                </Typography>
                <Typography>
                  {handleDateTime(privateSaleInfo?.startDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Estacionamento:
                </Typography>
                <Typography>{privateSaleInfo?.privateParkingName}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Situação:
                </Typography>
                <Typography>{privateSaleInfo?.situation}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Motorista:
                </Typography>
                <Typography>
                  {handleDriverText(privateSaleInfo?.driverId)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Método de Pagamento:
                </Typography>
                <Typography>{privateSaleInfo?.paymentType}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Valor:
                </Typography>
                <Typography>{handleMoney(privateSaleInfo?.price)}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
      </>
    </Modal>
  );
};
