import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Table, TableText } from '../../../components';
import {
  fetchPrivateSalesList,
  useDispatch,
  useSelector,
  privateSalesListSlice,
  privateSaleSlice,
  fetchPrivateSaleInfo,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { VehiclesEntryTableToolbar } from './VehiclesEntryTableToolbar';

export const VehiclesEntryTable: React.FC = () => {
  const dispatch = useDispatch();
  const { privateSalesList, privateSalesListLoading } = useSelector(
    (state) => state.privateSalesListReducer,
  );
  const { filters } = useSelector((state) => state.privateSalesFiltersReducer);
  const privateSalesFiltersValues = useSelector(
    (state) => state.privateSalesFiltersValuesReducer,
  );
  const { privateSalesPage } = useSelector(
    (state) => state.privateSalesListReducer,
  );
  const responsiveMode = useResponsiveMode();

  const [rows, setRows] = useState<any[]>([]);

  const {
    startDateTime,
    endDateTime,
    plate,
    driverId,
    paymentMethod,
    privateParkingId,
  } = privateSalesFiltersValues;

  useEffect(() => {
    if (privateSalesList && privateSalesList !== undefined) {
      setRows(privateSalesList.page);
    } else setRows([]);
  }, [privateSalesList]);

  const handleInfo = (privateSaleId: GridRowId) => {
    dispatch(fetchPrivateSaleInfo({ privateSaleId: privateSaleId.toString() }));
    dispatch(privateSaleSlice.actions.togglePrivateSaleInfoIsOpen());
  };

  const handleDriverText = (driverId: string): string => {
    if (filters) {
      let info = undefined;
      info = find(filters.drivers, ['id', driverId]);
      if (info !== undefined) {
        return `${info.value}`;
      } else return `Indisponível`;
    }
    return `Indisponível`;
  };

  const columns: GridColDef[] = [
    {
      field: 'ticket',
      headerName: 'Ticket',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.ticketId} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'vehicle',
      headerName: 'Veículo',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.plate} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'paymentMethod',
      headerName: 'Pagamento',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.paymentMethod} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Hora de registro',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.registerDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'startDateTime',
      headerName: 'Hora de início',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.startDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'driver',
      headerName: 'Motorista',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <TableText label={handleDriverText(params.row.driverId)} size={27} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'price',
      headerName: 'Valor',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.price} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <Button
            variant='contained'
            label='Detalhar'
            startIcon={<InfoIcon />}
            size='small'
            onClick={() => handleInfo(params.id)}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          />
        ) : (
          <MuiButton
            variant='contained'
            size='small'
            onClick={() => handleInfo(params.id)}
            sx={{
              minWidth: 0,
            }}
          >
            <InfoTwoToneIcon />
          </MuiButton>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'ticket',
      headerName: 'Ticket',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.ticketId} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'vehicle',
      headerName: 'Veículo',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.plate} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'paymentMethod',
      headerName: 'Pagamento',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.paymentMethod} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Hora de registro',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.registerDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'startDateTime',
      headerName: 'Hora de início',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.startDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'driver',
      headerName: 'Motorista',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <TableText label={handleDriverText(params.row.driverId)} size={27} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'price',
      headerName: 'Valor',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.price} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      // description: 'This column has a value getter and is not sortable.',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <MuiButton
          variant='contained'
          size='small'
          onClick={() => handleInfo(params.id)}
          sx={{
            minWidth: 0,
          }}
        >
          <InfoTwoToneIcon />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      page={privateSalesPage}
      changePage={(value: number) =>
        privateSalesListSlice.actions.setPrivateSalesPage(value)
      }
      rows={rows}
      rowCount={privateSalesList ? privateSalesList.size : 0}
      getRowId={(row) => row.ticketId}
      fetchRows={() =>
        dispatch(
          fetchPrivateSalesList({
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            plate: plate,
            driverId: driverId,
            paymentMethod: paymentMethod,
            privateParkingId: privateParkingId,
          }),
        )
      }
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={
        responsiveMode === 'phone' ||
        responsiveMode === 'tablet' ? undefined : (
          <VehiclesEntryTableToolbar />
        )
      }
      loading={privateSalesListLoading}
    />
  );
};
