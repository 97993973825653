// export const HOME = '/';
export const ACTIONS_HISTORY = '/acoes';
export const ADMINISTRATIVE_DASHBOARD = '/controle/administrativo';
export const AREAS_MANAGEMENT = '/areas';
export const CONFIGURATION_CREATE = '/configuracoes/novo';
export const CONFIGURATION_UPDATE = '/configuracoes/edicao';
export const CONFIGURATIONS_MANAGEMENT = '/configuracoes';
export const CREDENTIALS_CREATE = '/credenciais/novo';
export const CREDENTIALS_MANAGEMENT = '/credenciais';
export const CREDENTIALS_UPDATE = '/credenciais/edicao';
export const LOGIN = '/';
export const NOTIFICATIONS_MANAGEMENT = '/notificacoes';
export const NOTIFICATIONS_EDIT_RECORDS = '/notificacoes/registros';
export const OPERATIONAL_DASHBOARD = '/controle/operacional';
export const PERMISSIONS_MANAGEMENT = '/permissoes';
export const PRIVATE_MANAGEMENT = '/privados';
export const PROFILE = '/perfil';
export const ROUTE_CREATE = '/trechos/novo';
export const ROUTE_UPDATE = '/trechos/edicao';
export const ROUTES_MANAGEMENT = '/trechos';
export const SESSIONS_HISTORY = '/sessoes';
export const SALES_MANAGEMENT = '/vendas';
export const USER_CREATE = '/usuarios/novo';
export const USER_ROUTES = '/usuarios/trechos';
export const USER_UPDATE = '/usuarios/edicao';
export const USERS_MANAGEMENT = '/usuarios';
