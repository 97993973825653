import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map, replace } from 'lodash';
import {
  fetchPrivateSalesExportExcelService,
  fetchPrivateSalesListService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { handleDateTime, handleMoney } from '../../utils/Helpers';
import { RootState } from '../Store';
import {
  PrivateSalesFiltersValuesFetchType,
  PrivateSalesListStateType,
} from './types';

const initialState: PrivateSalesListStateType = {
  privateSalesList: null,
  privateSalesListLoading: false,
  privateSalesExportExcelLoading: false,
  privateSalesExportModalIsOpen: false,
  privateSalesExportPercentage: 0,
  privateSalesPage: 1,
};

export const fetchPrivateSalesList = createAsyncThunk(
  'privateSalesList',
  async (params: PrivateSalesFiltersValuesFetchType, { getState }) => {
    const state = getState() as RootState;
    return await fetchPrivateSalesListService({
      ...params,
      paymentMethod:
        params.paymentMethod === 'Todos' ? '' : params.paymentMethod,
      page: state.privateSalesListReducer.privateSalesPage.toString(),
    });
  },
);

export const fetchPrivateSalesExportExcel = createAsyncThunk(
  'privateSalesExportExcel',
  async (
    params: {
      startDateTime: string;
      endDateTime: string;
      plate: string;
      paymentMethod: string;
      privateParkingId: string;
      driverId: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchPrivateSalesExportExcelService(params);
    return { ...res, filters: state.privateSalesFiltersReducer.filters };
  },
);

export const privateSalesListSlice = createSlice({
  name: 'privateSalesList',
  initialState,
  reducers: {
    setPrivateSalesPage(state, action: PayloadAction<number>) {
      state.privateSalesPage = action.payload;
    },
    setPrivateSalesExportPercentage(state, action: PayloadAction<number>) {
      state.privateSalesExportPercentage = action.payload;
    },
    toggleExportModalIsOpen(state) {
      state.privateSalesExportModalIsOpen =
        !state.privateSalesExportModalIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrivateSalesList.pending, (state) => {
      return {
        ...state,
        privateSalesList: null,
        privateSalesListLoading: true,
      };
    }),
      builder.addCase(fetchPrivateSalesList.fulfilled, (state, res) => {
        const size = res.payload.data.size;
        const totalAmount = res.payload.data.totalAmount;
        const page = map(res.payload.data.page, (item) => {
          return {
            ...item,
            ticketId: item.ticketId,
            situation: item.situation,
            plate: item.plate,
            category: item.category,
            paymentMethod: item.paymentMethod,
            registerDateTime: handleDateTime(item.registerDateTime),
            startDateTime: handleDateTime(item.startDateTime),
            endDateTime: handleDateTime(item.endDateTime),
            privateParkingId: item.privateParkingId,
            driverId: item.driverId,
            price: handleMoney(item.price),
            privateParkingName: item.privateParkingName,
            cardId: item.cardId,
            pixCode: item.pixCode,
          };
        });
        return {
          ...state,
          privateSalesList: { page, size, totalAmount },
          privateSalesListLoading: false,
        };
      }),
      builder.addCase(fetchPrivateSalesList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          privateSalesListLoading: false,
        };
      });
    builder.addCase(fetchPrivateSalesExportExcel.pending, (state) => {
      return {
        ...state,
        privateSalesExportExcelLoading: true,
        privateSalesExportLoading: true,
        privateSalesExportPercentage: 0,
      };
    }),
      builder.addCase(fetchPrivateSalesExportExcel.fulfilled, (state, res) => {
        return {
          ...state,
          privateSalesExportExcelLoading: false,
          privateSalesExportPercentage: 10,
        };
      }),
      builder.addCase(fetchPrivateSalesExportExcel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          privateSalesExportExcelLoading: false,
          privateSalesExportLoading: false,
          privateSalesExportPercentage: 0,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = privateSalesListSlice.actions;
