import { AnyAction, CombinedState, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import {
  actionsReducer,
  ActionsStateType,
  actionsListReducer,
  ActionsListStateType,
  actionsFiltersReducer,
  ActionsFiltersStateType,
  actionsFiltersValuesReducer,
  ActionsFiltersValuesStateType,
  administrativeDashboardReducer,
  AdministrativeDashboardStateType,
  areaReducer,
  AreaStateType,
  areasReducer,
  AreasStateType,
  authReducer,
  AuthStateType,
  configurationReducer,
  ConfigurationStateType,
  configurationsReducer,
  ConfigurationsStateType,
  credentialsReducer,
  CredentialsStateType,
  credentialsFiltersReducer,
  credentialsFiltersValuesReducer,
  CredentialsFiltersStateType,
  CredentialFiltersValueStateType,
  credentialsListSliceReducer,
  CredentialsListStateType,
  notificationReducer,
  notificationsFiltersReducer,
  notificationsFiltersValuesReducer,
  notificationsListReducer,
  NotificationsFiltersStateType,
  NotificationsFiltersValuesStateType,
  NotificationsListStateType,
  NotificationStateType,
  operationalDashboardReducer,
  OperationalDashboardStateType,
  permissionsReducer,
  PermissionsStateType,
  privateSaleReducer,
  privateSalesFiltersReducer,
  privateSalesFiltersValuesReducer,
  privateSalesListReducer,
  PrivateSalesFiltersStateType,
  PrivateSalesFiltersValuesStateType,
  PrivateSalesListStateType,
  PrivateSaleStateType,
  routeReducer,
  routesFiltersReducer,
  routesFiltersValuesReducer,
  routesListReducer,
  routesMapReducer,
  RoutesFiltersStateType,
  RoutesFiltersValuesStateType,
  RoutesListStateType,
  RoutesMapStateType,
  RouteStateType,
  saleReducer,
  salesFiltersReducer,
  salesFiltersValuesReducer,
  salesListReducer,
  SalesFiltersStateType,
  SalesFiltersValuesStateType,
  SalesListStateType,
  SaleStateType,
  sessionReducer,
  sessionsFiltersReducer,
  sessionsFiltersValuesReducer,
  sessionsListReducer,
  SessionsFiltersStateType,
  SessionsFiltersValuesStateType,
  SessionsListStateType,
  SessionStateType,
  userReducer,
  usersFiltersReducer,
  usersFiltersValuesReducer,
  usersListReducer,
  UsersFiltersStateType,
  UsersFiltersValuesStateType,
  UsersListStateType,
  UserStateType,
} from './slices';

const combinedReducer = combineReducers({
  actionsReducer: actionsReducer,
  actionsListReducer: actionsListReducer,
  actionsFiltersReducer: actionsFiltersReducer,
  actionsFiltersValuesReducer: actionsFiltersValuesReducer,
  administrativeDashboardReducer: administrativeDashboardReducer,
  areaReducer: areaReducer,
  areasReducer: areasReducer,
  authReducer: authReducer,
  credentialsReducer: credentialsReducer,
  configurationReducer: configurationReducer,
  configurationsReducer: configurationsReducer,
  credentialsFiltersReducer: credentialsFiltersReducer,
  credentialsFiltersValuesReducer: credentialsFiltersValuesReducer,
  credentialsListSliceReducer: credentialsListSliceReducer,
  notificationsFiltersReducer: notificationsFiltersReducer,
  notificationsFiltersValuesReducer: notificationsFiltersValuesReducer,
  notificationsListReducer: notificationsListReducer,
  notificationReducer: notificationReducer,
  operationalDashboardReducer: operationalDashboardReducer,
  permissionsReducer: permissionsReducer,
  privateSalesFiltersReducer: privateSalesFiltersReducer,
  privateSalesFiltersValuesReducer: privateSalesFiltersValuesReducer,
  privateSalesListReducer: privateSalesListReducer,
  privateSaleReducer: privateSaleReducer,
  routesFiltersReducer: routesFiltersReducer,
  routesFiltersValuesReducer: routesFiltersValuesReducer,
  routesListReducer: routesListReducer,
  routesMapReducer: routesMapReducer,
  routeReducer: routeReducer,
  salesFiltersReducer: salesFiltersReducer,
  salesFiltersValuesReducer: salesFiltersValuesReducer,
  salesListReducer: salesListReducer,
  saleReducer: saleReducer,
  sessionsFiltersReducer: sessionsFiltersReducer,
  sessionsFiltersValuesReducer: sessionsFiltersValuesReducer,
  sessionsListReducer: sessionsListReducer,
  sessionReducer: sessionReducer,
  usersFiltersReducer: usersFiltersReducer,
  usersFiltersValuesReducer: usersFiltersValuesReducer,
  usersListReducer: usersListReducer,
  userReducer: userReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        actionsReducer: ActionsStateType;
        actionsListReducer: ActionsListStateType;
        actionsFiltersReducer: ActionsFiltersStateType;
        actionsFiltersValuesReducer: ActionsFiltersValuesStateType;
        administrativeDashboardReducer: AdministrativeDashboardStateType;
        areaReducer: AreaStateType;
        areasReducer: AreasStateType;
        authReducer: AuthStateType;
        configurationReducer: ConfigurationStateType;
        configurationsReducer: ConfigurationsStateType;
        credentialsReducer: CredentialsStateType;
        credentialsFiltersReducer: CredentialsFiltersStateType;
        credentialsFiltersValuesReducer: CredentialFiltersValueStateType;
        credentialsListSliceReducer: CredentialsListStateType;
        notificationsFiltersReducer: NotificationsFiltersStateType;
        notificationsFiltersValuesReducer: NotificationsFiltersValuesStateType;
        notificationsListReducer: NotificationsListStateType;
        notificationReducer: NotificationStateType;
        operationalDashboardReducer: OperationalDashboardStateType;
        permissionsReducer: PermissionsStateType;
        privateSalesFiltersReducer: PrivateSalesFiltersStateType;
        privateSalesFiltersValuesReducer: PrivateSalesFiltersValuesStateType;
        privateSalesListReducer: PrivateSalesListStateType;
        privateSaleReducer: PrivateSaleStateType;
        routesFiltersReducer: RoutesFiltersStateType;
        routesFiltersValuesReducer: RoutesFiltersValuesStateType;
        routesListReducer: RoutesListStateType;
        routesMapReducer: RoutesMapStateType;
        routeReducer: RouteStateType;
        salesFiltersReducer: SalesFiltersStateType;
        salesFiltersValuesReducer: SalesFiltersValuesStateType;
        salesListReducer: SalesListStateType;
        saleReducer: SaleStateType;
        sessionsFiltersReducer: SessionsFiltersStateType;
        sessionsFiltersValuesReducer: SessionsFiltersValuesStateType;
        sessionsListReducer: SessionsListStateType;
        sessionReducer: SessionStateType;
        usersFiltersReducer: UsersFiltersStateType;
        usersFiltersValuesReducer: UsersFiltersValuesStateType;
        usersListReducer: UsersListStateType;
        userReducer: UserStateType;
      }>
    | undefined,
  action: AnyAction,
) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'actionsFiltersValuesReducer',
    'credentialsFiltersValuesReducer',
    'notificationsFiltersValuesReducer',
    'routesFiltersValuesReducer',
    'sessionsFiltersValuesReducer',
    'usersFiltersValuesReducer',
  ],
  transforms: [
    encryptTransform({
      secretKey: `${process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY}`,
      onError: function (error) {
        // Handle the error.
        console.log(`${error}`);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Here is the export of the Store
export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const Persistor = persistStore(Store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// This must be done this way so they can work with typescript
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
