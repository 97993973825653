import { useWorker } from '@koale/useworker';
import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { Button } from '../../../components';
import {
  useDispatch,
  useSelector,
  privateSalesListSlice,
  fetchPrivateSalesExportExcel,
} from '../../../stores';
import { handleMoney } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';
import {
  exportPrivateSalesToCSV,
  handleApiDataPrivateSalesToCSV,
} from './exports';

export const VehiclesEntryTableToolbar = () => {
  const dispatch = useDispatch();
  const { privateSalesList, privateSalesExportExcelLoading } = useSelector(
    (state) => state.privateSalesListReducer,
  );
  const privateSalesFiltersValues = useSelector(
    (state) => state.privateSalesFiltersValuesReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [exportPrivateSalesToCSVWorker] = useWorker(
    handleApiDataPrivateSalesToCSV,
  );

  const handleExportExcel = () => {
    dispatch(fetchPrivateSalesExportExcel(privateSalesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportPrivateSalesToCSV(
            res.payload.data,
            res.payload.filters,
            dispatch,
            exportPrivateSalesToCSVWorker,
          ).then(() => {
            dispatch(privateSalesListSlice.actions.toggleExportModalIsOpen());
          });
        } else {
          dispatch(privateSalesListSlice.actions.toggleExportModalIsOpen());
        }
      },
    );
  };

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        {responsiveMode !== 'phone' && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              label='Exportar em excel'
              startIcon={<DownloadIcon />}
              onClick={() => handleExportExcel()}
              loading={privateSalesExportExcelLoading}
            />
          </div>
        )}
        <Typography margin={1} sx={{ textAlign: 'right', paddingRight: 2 }}>
          Qtd.: {privateSalesList?.size}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Total: {handleMoney(privateSalesList?.totalAmount)}
        </Typography>
      </div>
    </StyledTableHeader>
  );
};
