import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateSalesFiltersValuesStateType } from './types';

const initialState: PrivateSalesFiltersValuesStateType = {
  days: '0',
  driverId: '0',
  startDateTime: moment(
    moment().format('yyyy-MM-DD') + ' ' + moment().format('00:00'),
  )
    .format('yyyy-MM-DD HH:mm:ss')
    .toString(),
  plate: '',
  paymentType: '0',
  privateParkingId: '0',
};

export const privateSalesFiltersValuesSlice = createSlice({
  name: 'privateSalesFiltersValues',
  initialState,
  reducers: {
    setPrivateSalesFiltersValues(
      state,
      action: PayloadAction<PrivateSalesFiltersValuesStateType>,
    ) {
      state.days = action.payload.days;
      state.driverId = action.payload.driverId;
      state.startDateTime = action.payload.startDateTime;
      state.plate = action.payload.plate;
      state.paymentType = action.payload.paymentType;
      state.privateParkingId = action.payload.privateParkingId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPrivateSalesFiltersValues } =
  privateSalesFiltersValuesSlice.actions;

// // now available:
// filtersValuesSlice.actions.setSalesFiltersValues();
// // also available:
// filtersValuesSlice.caseReducers.increment(0, { type: 'increment', payload: 5 });
