import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchPrivateSalesFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateSalesFiltersStateType } from './types';

const initialState: PrivateSalesFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchPrivateSalesFilters = createAsyncThunk(
  'salesPrivateFilters',
  async () => {
    return await fetchPrivateSalesFiltersService();
  },
);

export const privateSalesFiltersSlice = createSlice({
  name: 'salesPrivateFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrivateSalesFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchPrivateSalesFilters.fulfilled, (state, res) => {
        const { privateParks, cloudContracts, paymentMethods, drivers } =
          res.payload.data;

        let handledPaymentMethods = {};
        map(paymentMethods, (item) => {
          if (item.id === '') {
            handledPaymentMethods = {
              ...handledPaymentMethods,
              Todos: item.value,
            };
          } else {
            handledPaymentMethods = {
              ...handledPaymentMethods,
              [item.id]: item.value,
            };
          }
        });

        const filters = {
          privateParks: privateParks,
          cloudContracts: cloudContracts,
          paymentMethods: handledPaymentMethods,
          drivers: drivers,
        };

        return {
          ...state,
          filters: filters,
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchPrivateSalesFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = privateSalesFiltersSlice.actions;
