/* eslint-disable pii/no-phone-number */
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Grid, IconButton, Typography } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Dropdown,
  DatePicker,
  TimePicker,
  PageWrapper,
  FiltersGrid,
  Accordion,
  DropdownInput,
  Switch,
} from '../../components';
import {
  fetchPrivateSalesFilters,
  useDispatch,
  useSelector,
  privateSalesFiltersValuesSlice,
  fetchPrivateSalesList,
  privateSalesListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { SalesExportModal } from '../SalesManagement/modals';
import { daysOptions } from './constants';
import { PrivateSaleInfoModal } from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { VehiclesEntryTable } from './table';

export const PrivateSalesManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.privateSalesFiltersReducer,
  );
  const filtersValues = useSelector(
    (state) => state.privateSalesFiltersValuesReducer,
  );
  const { privateSalesPage, privateSalesListLoading } = useSelector(
    (state) => state.privateSalesListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    days: filtersValues.days,
    startDate: moment(filtersValues.startDateTime).format('yyyy-MM-DD'),
    startTime: moment(filtersValues.startDateTime).format('HH:mm'),
    plate: filtersValues.plate,
    driverId: filtersValues.driverId,
    paymentType: filtersValues.paymentType,
    privateParkingId: filtersValues.privateParkingId,
  });

  useEffect(() => {
    const values = {
      ...omit(filtersValues, ['days']),
      page: privateSalesPage.toString(),
    };
    dispatch(fetchPrivateSalesFilters());
    dispatch(fetchPrivateSalesList(values));
  }, []);

  useEffect(() => {
    const values = {
      days: formData.days,
      startDateTime: moment(formData.startDate + ' ' + formData.startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: formData.plate,
      driverId: formData.driverId,
      paymentType: formData.paymentType === '' ? 'Todos' : formData.paymentType,
      privateParkingId: formData.privateParkingId,
      page: privateSalesPage,
    };

    dispatch(
      privateSalesFiltersValuesSlice.actions.setPrivateSalesFiltersValues(
        values,
      ),
    );
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      page: '1',
    };

    dispatch(
      privateSalesFiltersValuesSlice.actions.setPrivateSalesFiltersValues(
        values,
      ),
    );
    dispatch(privateSalesListSlice.actions.setPrivateSalesPage(1));
    dispatch(fetchPrivateSalesList(omit(values, ['days'])));
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const startDate = moment().format('yyyy-MM-DD');
    const startTime = moment().format('00:00');
    const values = {
      days: '0',
      startDateTime: moment(startDate + ' ' + startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: '',
      driverId: '0',
      paymentType: '0',
      privateParkingId: '0',
      page: '1',
    };

    setFormData({
      days: '0',
      startDate: moment().format('yyyy-MM-DD'),
      startTime: moment().format('00:00'),
      plate: '',
      driverId: '0',
      paymentType: '0',
      privateParkingId: '0',
    });
    dispatch(
      privateSalesFiltersValuesSlice.actions.setPrivateSalesFiltersValues(
        values,
      ),
    );
    dispatch(fetchPrivateSalesList(omit(values, ['days'])));
  };

  const handleSelectCustomizedDays = (value: string, field: string) => {
    if (field === 'startTime' || field === 'endTime') {
      setFormData({
        ...formData,
        [field]: value,
      });
    } else {
      const daysValue = '1000';

      setFormData({
        ...formData,
        days: daysValue,
        [field]: value,
      });
    }
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <ReceiptIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciar privativo
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <FiltersGrid loading={!filters && filtersLoading} loadingRows={3}>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Filtro de dias'
                  options={daysOptions}
                  value={formData.days}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      days: value,
                      startDate: moment()
                        .subtract(parseInt(value), 'days')
                        .format('yyyy-MM-DD'),
                      startTime: moment().format('00:00'),
                    });
                  }}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data inicial'
                  value={formData.startDate}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startDate')
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora inicial'
                  value={formData.startTime}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startTime')
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='plate'
                  type='text'
                  margin='normal'
                  label='Placa'
                  value={formData.plate}
                  onChange={(value) =>
                    setFormData({ ...formData, plate: value })
                  }
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Motorista'
                  value={formData.driverId}
                  options={filters && filters.drivers}
                  onChange={(value) =>
                    setFormData({ ...formData, driverId: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Pagamento'
                  value={formData.paymentType}
                  options={filters && filters.paymentMethods}
                  onChange={(value) => {
                    setFormData({ ...formData, paymentType: value });
                  }}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Estacionamento'
                  value={formData.privateParkingId}
                  options={filters && filters.privateParks}
                  onChange={(value) => {
                    setFormData({ ...formData, privateParkingId: value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledFiltersBtnsGrid
                item
                lg={1.5}
                md={6}
                sm={6}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  disabled={privateSalesListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={privateSalesListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </Grid>
          </FiltersGrid>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <FiltersGrid loading={filtersLoading} loadingRows={3}>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Filtro de dias'
                options={daysOptions}
                value={formData.days}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    days: value,
                    startDate: moment()
                      .subtract(parseInt(value), 'days')
                      .format('yyyy-MM-DD'),
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data inicial'
                value={formData.startDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    startDate: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora inicial'
                value={formData.startTime}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    startTime: value,
                  });
                }}
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='plate'
                type='text'
                margin='normal'
                label='Placa'
                value={formData.plate}
                onChange={(value) => setFormData({ ...formData, plate: value })}
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Motorista'
                value={formData.driverId}
                options={filters && filters.drivers}
                onChange={(value) =>
                  setFormData({ ...formData, driverId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Pagamento'
                value={formData.paymentType}
                options={filters && filters.paymentMethods}
                onChange={(value) =>
                  setFormData({ ...formData, paymentType: value })
                }
              />
            </Grid>
            <StyledFiltersBtnsGrid
              item
              lg={1.5}
              md={4}
              sm={4}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Filtrar'
                startIcon={<FilterAltIcon />}
                sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                onClick={handleSubmit}
                disabled={privateSalesListLoading}
              />
              <IconButton
                color='secondary'
                aria-label='Remover filtros'
                onClick={handleReset}
                disabled={privateSalesListLoading}
              >
                <CancelIcon fontSize='large' />
              </IconButton>
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Accordion>
      )}
      <Grid container margin={3}>
        <VehiclesEntryTable />
      </Grid>
      <PrivateSaleInfoModal />
      <SalesExportModal />
    </PageWrapper>
  );
};
